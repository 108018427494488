<template>
  <div class="block">
    <Sidebar />

    <router-view name="inbound" v-slot="{ Component }">
      <transition name="up" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>

  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'

export default {
  name: 'Inbound',

  components: {
    Sidebar
  },
}
</script>

<style scoped>

</style>
